import React from 'react';
import type { FC } from 'react';

import MainLandingPage from 'components/pagesComponents/MainLandingPage/C';

const MainLanding: FC = () => {
  return <MainLandingPage />;
};

export default MainLanding;
