import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import { H1, Subtitle1 } from '@a3/chameleon/src/components/basic/Typography';
import cn from 'classnames';
import Image from 'next/image';
import type { FC } from 'react';

import { gtmEvents } from 'constants/gtmEvent';
import pageUrls from 'constants/pagesUrls';

import HeroItem from 'components/HeroBlock/components/HeroItem';
import { heroItemsData } from 'components/HeroBlock/components/HeroItem/data';
import MobileContent from 'components/HeroBlock/components/MobileContent';
import NavigationMenu from 'components/HeroBlock/components/NavigationMenu';
import { classBlock } from 'components/HeroBlock/components/types';
import ContentContainer from 'components/basic/ContentContainer';
import DropdownClick from 'components/complex/DropdownClick/';

import s from './HeroBlock.module.scss';

const HeroBlock: FC = () => {
  return (
    <Typograf>
      <NavigationMenu />
      <div className={s.background}>
        <ContentContainer className={s.content}>
          <div className={cn(s.textBlock, 'grid')}>
            <H1 className={cn(s.title, 'g-col-xss-4 g-col-s-8')}>
              Приложение для&nbsp;оплаты
              <br />
              ЖКХ в Москве
            </H1>
            <div className={cn(s.image, 'g-col-xs-4 g-col-s-8')}>
              <Image
                src="/img/HeroBlock/C/QR.png"
                width={100}
                height={100}
                priority
                alt="Qr Code"
              />
              <Subtitle1 className={s.text}>
                Скачать приложение бесплатно
              </Subtitle1>
            </div>
            <MobileContent
              downloadHref={pageUrls.downloadStoreTestC}
              appStoreEvent={gtmEvents.clickMainAppStoreTestC}
              googlePlayEvent={gtmEvents.clickMainGooglePlayTestC}
            />
          </div>
          <div className={cn(s.heroItemsWrapper, 'grid')}>
            {heroItemsData.map(({ title, image, alt }) => (
              <DropdownClick
                className="g-col-xss-4"
                key={image}
                classBlock={classBlock}
                analyticsEvent={gtmEvents.clickDownloadStoreMainTestC}
                downloadHref={pageUrls.downloadStoreTestC}
              >
                <HeroItem title={title} image={image} alt={alt} />
              </DropdownClick>
            ))}
          </div>
        </ContentContainer>
      </div>
    </Typograf>
  );
};

export default HeroBlock;
