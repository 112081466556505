import type { FC } from 'react';

import { gtmEvents } from 'constants/gtmEvent';
import pageUrls from 'constants/pagesUrls';

import Advantages from 'components/Advantages';
import DownloadApplication from 'components/DownloadApplication';
import { ImagesPathEnum } from 'components/DownloadApplication/types';
import Faq from 'components/Faq';
import HeroBlock from 'components/HeroBlock/C';
import QrBlock from 'components/QrBlock';
import ReviewSlider from 'components/ReviewSlider';
import UniqueProposition from 'components/UniqueProposition';
import {
  firstLine,
  secondLine,
  thirdLine,
} from 'components/UniqueProposition/data';
import Page from 'components/layouts/Page';

const MainLandingPage: FC = () => {
  return (
    <Page withoutHeader seo={{ canonicalUrl: 'https://mosplatezhi.com' }}>
      <HeroBlock />
      <Advantages
        className="js-advantages"
        downloadHref={pageUrls.downloadStoreTestC}
        analyticsEvt={gtmEvents.clickDownloadStoreAdvantagesTestC}
      />
      <QrBlock />
      <UniqueProposition {...firstLine} className="js-unique-proposition" />
      <UniqueProposition isReverse {...secondLine} />
      <UniqueProposition {...thirdLine} />
      <DownloadApplication
        className="js-download-application"
        qrCodeWithMetrics={ImagesPathEnum.qrCodeTestC}
        appStoreHref={pageUrls.downloadStoreTestC}
        googlePlayHref={pageUrls.downloadStoreTestC}
        appStoreEvent={gtmEvents.clickMainAppStoreTestC}
        googlePlayEvent={gtmEvents.clickMainGooglePlayTestC}
      />
      <ReviewSlider className="js-review-slider" />
      <Faq
        downloadHref={pageUrls.downloadStoreTestC}
        analyticsEvt={gtmEvents.clickDownloadStoreFaqTestC}
        className="js-faq"
      />
    </Page>
  );
};

export default MainLandingPage;
